import getPrimaryOriginCode from '../getPrimaryOriginCode';

const getPrimaryOrigin = (origins) => {
  if (!origins) {
    return null;
  }
  const originCodes = origins.reduce((acc, cur) => [...acc, cur.code], []);

  const primaryOriginCode = getPrimaryOriginCode(originCodes);

  return origins.find((origin) => origin.code === primaryOriginCode);
};

export default getPrimaryOrigin;
