import Decimal from 'decimal.js';

const convertCashToPoints = (cash, tiers) =>
  tiers
    .reduce((result, tier) => {
      if (new Decimal(cash).greaterThanOrEqualTo(tier.min)) {
        const pointsAmountForTier = Decimal.min(tier.max, cash)
          .minus(tier.min)
          .dividedBy(tier.rate);

        return result.plus(pointsAmountForTier);
      }

      return result;
    }, new Decimal(0))
    .toDecimalPlaces(0, Decimal.ROUND_HALF_EVEN);

export default convertCashToPoints;
