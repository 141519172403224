import Decimal from 'decimal.js';

const convertPointsToCash = (points, tiers) =>
  tiers
    .reduce(
      (result, tier) => {
        if (new Decimal(points).greaterThan(result.points)) {
          const maxPointsForTier = Decimal.sub(tier.max, tier.min).dividedBy(
            tier.rate,
          );
          const pointsForTier = Decimal.min(
            maxPointsForTier,
            Decimal.sub(points, result.points),
          );
          const cashAmountForTier = pointsForTier.times(tier.rate);

          return {
            cash: result.cash.plus(cashAmountForTier),
            points: result.points.plus(pointsForTier),
          };
        }

        return result;
      },
      {
        cash: new Decimal(0),
        points: new Decimal(0),
      },
    )
    .cash.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);

export default convertPointsToCash;
