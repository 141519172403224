import type { UI } from '@/v2/types';
import { convertCashToPoints } from '@/lib/pointsPay';
import getLeadInPriceFromProcuredOffer from '@/utils/getLeadInPriceFromProcuredOffer';
import { EcommerceItem } from '@/libs/v2/utils/dataLayer/types';
import { Charges } from '@/v2/components/ui/Price/types';
import { ProcuredOffer } from '@/v2/types/ui';

export type Property = {
  propertyContent: UI.Property;
  procuredOffer: UI.ProcuredOffer | null;
  packageOffer?: {
    charges?: Charges;
    roomTypeName: string;
  };
};

export type EcommerceItemProps = {
  property: Property;
  originCode: string | undefined;
  tiers?: UI.QFFPointTiersInstance;
  price?: number;
  index?: number;
  itemVariant?: string;
  forceOverridePrice?: boolean;
  destinationTitle?: string;
  procuredOffer?: ProcuredOffer;
};
const getEcommerceItem = ({
  property,
  price: totalAmount,
  tiers,
  index,
  originCode,
  itemVariant,
  forceOverridePrice = false,
  destinationTitle,
  procuredOffer,
}: EcommerceItemProps): EcommerceItem => {
  const procuredOfferContent = property?.procuredOffer || procuredOffer;
  const propertyContent = property?.propertyContent || property;
  const destinationCode =
    procuredOfferContent?.destinationCode ||
    propertyContent?.destination?.searchConfig?.destinationCode;
  const price = forceOverridePrice
    ? totalAmount
    : totalAmount ??
      (
        procuredOfferContent &&
        getLeadInPriceFromProcuredOffer(procuredOfferContent, {
          originCode,
          destinationCode,
        })
      )?.total?.amount;

  return {
    item_id: propertyContent.id,
    item_name: propertyContent.name,
    item_variant: itemVariant || procuredOfferContent?.rooms[0]?.room?.name,
    index,
    item_category: propertyContent.destination?.title ?? destinationTitle,
    has_offer: !!procuredOfferContent,
    quantity: 1,
    price: price && typeof price === 'string' ? Number(price) : price,
    points_value:
      price && tiers ? convertCashToPoints(price, tiers).toNumber() : undefined,
  };
};

export default getEcommerceItem;
