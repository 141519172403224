import qffPointTiers from '@/data/qffPointTiers';
import enrichTiersWithInfinity from '@/utils/enrichTiersWithInfinity';
import convertCashToPoints from './convertCashToPoints';

const convertCashToPreviousPoints = (cash) => {
  const { tiers } = enrichTiersWithInfinity(qffPointTiers);
  return convertCashToPoints(cash, tiers);
};

export default convertCashToPreviousPoints;
