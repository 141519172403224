const enrichTiersWithInfinity = (input) => {
  const tiers = [...input.tiers];

  const lastTier = tiers.pop();
  lastTier.max = lastTier.max || Number.POSITIVE_INFINITY;

  return {
    ...input,
    tiers: [...tiers, lastTier],
  };
};

export default enrichTiersWithInfinity;
