import { MEL, SYD } from '@/constants/ORIGINS';

const getPrimaryOriginCode = (originCodes) => {
  if (!originCodes) {
    return undefined;
  }

  if (originCodes.includes(SYD)) {
    return SYD;
  }

  if (originCodes.includes(MEL)) {
    return MEL;
  }

  return originCodes.sort()[0];
};

export default getPrimaryOriginCode;
