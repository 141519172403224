import Decimal from 'decimal.js';
import convertCashToPoints from './convertCashToPoints';
import convertPointsToCash from './convertPointsToCash';

export const roundDown = {
  callback: (a, b) => a > b,
  correction: -1,
};

export const roundUp = {
  callback: (a, b) => a < b,
  correction: +1,
};

const convertPointsToValue = (points, tiers, amount, adjustment = roundUp) => {
  const { callback, correction } = adjustment;
  const cash = convertPointsToCash(points, tiers);

  if (callback(convertCashToPoints(cash, tiers), points)) {
    return convertPointsToValue(points + correction, tiers, amount, {
      callback,
      correction,
    });
  }

  return Decimal.div(cash, amount);
};

export default convertPointsToValue;
