import getPrimaryOrigin from '@/utils/getPrimaryOrigin';

const getLeadInPriceFromProcuredOffer = (
  procuredOffer,
  { originCode, destinationCode },
) => {
  const { packageOffers } = procuredOffer.rooms[0].leadInOffers[0];

  if (!originCode || !destinationCode) {
    return null;
  }

  const route = `${originCode}${destinationCode}`;

  if (packageOffers) {
    return packageOffers[route];
  }

  return null;
};

export const getLeadInPriceFromCampaignTile = (tile, filteredOriginCode) => {
  const { procuredOffer, propertyContent } = tile;
  const { destination } = propertyContent;
  const { origins, destinationCode } = destination;

  const origin = getPrimaryOrigin(origins);

  const calculatedOriginCode = filteredOriginCode || origin?.code;

  if (!calculatedOriginCode) {
    return null;
  }

  return getLeadInPriceFromProcuredOffer(procuredOffer, {
    originCode: filteredOriginCode || origin.code,
    destinationCode,
  });
};

export default getLeadInPriceFromProcuredOffer;
