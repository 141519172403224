import usePageViewEventShared, {
  UsePageViewEventOptions,
} from '@/libs/v2/utils/dataLayer/hooks/usePageViewEvent';
import dataLayer from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';
import { useIsFeatureFlagLoaded } from '@/libs/v2/contexts/featureFlags';

const usePageViewEvent = (opts: UsePageViewEventOptions) => {
  const shouldUseEvent = useGA4Events();
  const isFlagLoaded = useIsFeatureFlagLoaded();

  return usePageViewEventShared(dataLayer, {
    ...opts,
    isDisabled: !isFlagLoaded || !shouldUseEvent,
  });
};

export default usePageViewEvent;
