import { useAuth } from '../../../components/AuthContext';
import Cookies from 'js-cookie';
import { getUserUuid } from '../../localStorage';
import { useEffect, useRef } from 'react';
import { PageViewArgs } from '../eventCreators/ga4';
import * as Sentry from '@sentry/browser';
import { useRouter } from 'next/router';

export const QFF_HASH_COOKIE_KEY = 'QF_VALUE';

export type DataLayer = {
  pageViewEvent: (data: PageViewArgs) => void;
};

export type UsePageViewEventOptions = {
  type: string;
  isDisabled?: boolean;
};

const usePageViewEvent = (
  datalayer: DataLayer,
  { type, isDisabled }: UsePageViewEventOptions,
) => {
  const { isMemberDetailsReady, memberDetails } = useAuth();
  const { asPath } = useRouter();
  const lastPageViewPathname = useRef<string | null>(null);
  const pathname = asPath?.split('?')?.[0];

  useEffect(() => {
    if (
      !pathname ||
      lastPageViewPathname.current === pathname ||
      !isMemberDetailsReady ||
      !type ||
      isDisabled
    ) {
      return;
    }

    // We use this setTimeout as the document.title update is delayed and
    // results in the title being 1 page behind when this event fires.
    setTimeout(() => {
      try {
        const qffHash = Cookies.get(QFF_HASH_COOKIE_KEY);
        const id = getUserUuid();
        const { points, memberId } = memberDetails || {};

        datalayer.pageViewEvent({
          user: {
            id: memberId ? id : undefined,
            qffHash,
            points,
            loginStatus: memberId ? 'logged in' : 'logged out',
          },
          page: {
            title: document.title,
            location: window.location.href,
            type,
            referrer: document.referrer,
          },
        });
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        lastPageViewPathname.current = pathname;
      }
    }, 0);
  }, [
    datalayer,
    isDisabled,
    isMemberDetailsReady,
    memberDetails,
    type,
    pathname,
  ]);
};

export default usePageViewEvent;
