import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Heading, ListItem, Paragraph, Text } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import Link from '../Link';
import withProps from '../withProps';

const withSubtleText = (subtle, Component) =>
  withProps({
    color: subtle ? 'greys.steel' : undefined,
  })(Component);

const ConditionsLink = ({ subtle, linkHoverColor }) =>
  withSubtleText(
    subtle,
    withProps({
      external: true,
    })(styled(Link.Text)`
      text-decoration: underline;

      &:hover {
        color: ${themeGet(`colors.${linkHoverColor}`)};
      }
    `),
  );

const ConditionsTitle = ({ subtle }) =>
  withSubtleText(
    subtle,
    withProps({
      mb: '3',
    })(Heading.h4),
  );

const ConditionsHeading = ({ subtle }) =>
  withSubtleText(
    subtle,
    withProps({
      mt: '8',
      mb: '3',
    })(Heading.h5),
  );

const ConditionsSubHeading = ({ subtle }) =>
  withSubtleText(
    subtle,
    withProps({
      mt: '6',
      mb: '3',
    })(Heading.h6),
  );

const ConditionsParagraph = ({ subtle }) =>
  withSubtleText(
    subtle,
    withProps({
      mt: '3',
      mb: '3',
      fontSize: 'inherit',
    })(Paragraph),
  );

const ConditionsTableCell = ({ subtle }) =>
  withSubtleText(
    subtle,
    withProps({
      textAlign: 'left',
    })(styled('td')`
      padding: 0;
      padding-right: 8px;
      padding-bottom: 12px;
      vertical-align: top;
    `),
  );

// React markdown does not allow tables with header cells but we don't want to show them (so far)
const ConditionsTableHeading = () => styled('th')`
  display: none;
`;

// eslint-disable-next-line react/prop-types
const ConditionsListItem =
  ({ subtle }) =>
  ({ children }) => {
    const SubtleText = withSubtleText(subtle, Text);
    return (
      <ListItem>{children && <SubtleText>{children}</SubtleText>}</ListItem>
    );
  };

const overrideRenderers = ({ subtle, linkHoverColor }) => ({
  paragraph: ConditionsParagraph({ subtle }),
  listItem: ConditionsListItem({ subtle }),
  // eslint-disable-next-line react/prop-types
  tableCell: ({ isHeader, children }) => {
    const Component = isHeader
      ? ConditionsTableHeading({ subtle })
      : ConditionsTableCell({ subtle });
    return <Component>{children}</Component>;
  },
  link: ConditionsLink({ subtle, linkHoverColor }),
  // eslint-disable-next-line react/prop-types
  heading: ({ level, children }) => {
    const Component =
      {
        1: ConditionsTitle({ subtle }),
        2: ConditionsHeading({ subtle }),
      }[level] || ConditionsSubHeading({ subtle });
    return <Component>{children}</Component>;
  },
});

const ConditionsMarkdown = ({ markdown, subtle, linkHoverColor }) => (
  <ReactMarkdown
    source={markdown}
    renderers={overrideRenderers({ subtle, linkHoverColor })}
  />
);

ConditionsMarkdown.propTypes = {
  markdown: PropTypes.string.isRequired,
  subtle: PropTypes.bool,
  linkHoverColor: PropTypes.string,
};

ConditionsMarkdown.defaultProps = {
  subtle: false,
  linkHoverColor: undefined,
};

export default ConditionsMarkdown;
